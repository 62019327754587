<template>
  <div>
    <div class="newsListDetail_bg">
      <img :src="newsList_bg" />
    </div>
    <div class="newsListDetail_head">
      <div class="newsListDetail_head_title" @click="toPath('/newsList')">
        新闻资讯
      </div>
      <div class="newsListDetail_head_title">
        &nbsp;>&nbsp; <span class="active">{{ info.newsTitle }}</span>
      </div>
    </div>
    <div class="newsListDetail_list">
      <div class="newsListDetail_list_title">
        {{ info.newsTitle }}
      </div>
      <div class="newsListDetail_list_time">
        发布时间：{{ info.year }}-{{ info.day }}
      </div>
      <div class="newsListDetail_list_value" v-html="info.newsContent"></div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
export default {
  name: "projectSchedule",
  data() {
    return {
      newsList_bg: require("../../static/image/newsList_bg.png"),
      info: {},
      name: "",
    };
  },
  mounted() {
    window.scroll(0, 0);
    const id = this.$route.query.id;
    this.name = this.$route.query.name;
    this.getInfo(id);
  },
  methods: {
    getInfo(id) {
      //获取独立的数据
      axios
        .get(`https://t2.zyjkjt.net/cms/api/app/news/${id}`)
        .then((res) => {
          this.info = res.data.data;
          const updateTime = new Date(this.info.publishTime);
          this.info.day =
            updateTime.getDate() > 9
              ? updateTime.getDate()
              : "0" + updateTime.getDate();
          this.info.year =
            updateTime.getFullYear() +
            "." +
            (updateTime.getMonth() + 1 > 9
              ? updateTime.getMonth() + 1
              : "0" + (updateTime.getMonth() + 1));
        });
    },
    toPath(path) {
      this.$router.push(path);
    },
  },
};
</script>
<style scoped lang="scss">
.newsListDetail_bg {
  img {
    width: 100%;
  }
}

.newsListDetail_head {
  width: 1280px;
  margin: 20px auto;
  font-size: 18px;
  font-weight: 400;
  color: #858585;
  line-height: 20px;
  overflow: hidden;

  .newsListDetail_head_title {
    margin-top: 40px;
    display: inline-block;
    cursor: pointer;
  }
  .active {
    color: #000;
  }
}

.newsListDetail_list {
  margin: 40px auto;
  width: 1280px;
  text-align: center;

  .newsListDetail_list_title {
    color: #333;
    font-size: 25px;
    font-weight: bold;
    line-height: 1;
    text-align: center;
  }

  .newsListDetail_list_time {
    font-size: 12px;
    font-weight: 400;
    color: #abaeb0;
    line-height: 17px;
    margin-top: 20px;
  }

  .newsListDetail_list_value {
    margin-top: 40px;
    text-align: left;
    margin-bottom: 40px;
  }
}
</style>
